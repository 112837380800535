/* eslint-disable no-console */
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import LaunchOutlinedIcon from '@material-ui/icons/LaunchOutlined';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';
import { Alert } from '@material-ui/lab';

import { ColorPalette } from '../theme/ColorPalette';
import { Bookings } from '../typings/user-bookings';
import { Badge } from '../typings/badges';
import { Certification } from '../typings/certificate';
import { JWTContext } from '../components/JWTContext';
import { getApiService } from '../api/api-request';
import { mergedConfig as config } from '../config';

import { MainTable } from './MainTable';

const useStyles = makeStyles((theme) => ({
  btnPreview: {
    [theme.breakpoints.up('md')]: {
      float: 'right',
    },
  },
  nestedTable: {
    padding: 0,
  },
  root: {
    '& .gridHeader': {
      // backgroundColor: ColorPalette.monochrome.grey20,
      color: ColorPalette.primary.dark,
      '& a': {
        display: 'inline-flex',
      },
    },
  },
  formContainer: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  textField: {
    maxWidth: 400,
  },
}));

const LUPreviewPage: FunctionComponent = () => {
  const { getJWT } = useContext(JWTContext);
  const apiService = getApiService(getJWT);

  const location = useLocation();
  const { t } = useTranslation();
  const classes = useStyles();
  const [bookings, setBookings] = useState<Bookings | null>(null);
  const [certificates, setCertificates] = useState<Certification[]>([]);
  const [badges, setBadges] = useState<Badge[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [userId, setUserId] = useState<string>('');
  const [userNotFound, setUserNotFound] = useState<boolean>(false);
  const [jiraLink, setJiraLink] = useState<string>('');

  const resetState = () => {
    setBookings(null);
    setCertificates([]);
    setBadges([]);
    setUserNotFound(false);
    setLoading(false);
    setLoaded(false);
  };

  const collectUserData = async (userIdValue: string) => {
    setLoading(true);

    setJiraLink(
      `https://jira.haufe.io/secure/CreateIssueDetails!init.jspa?pid=24484&issuetype=27&summary=Fehlende Buchung in der Lernumgebung.&description=Hallo zusammen, für oben genannte hmgUserId fehlen Buchungen. Könnt ihr euch das bitte anschauen&customfield_19053=${userIdValue}&assignee=team_BUM_Support&priority=11001`,
    );

    await apiService.syncBookingData(userIdValue).catch((e) => {
      console.log('Error syncing booking data', e);
      return null;
    });
    const bookingData = await apiService.getBookingData(userIdValue).catch((e) => {
      console.log('Error getting booking data', e);
      return null;
    });

    if (!bookingData) {
      setUserNotFound(true);
      setLoading(false);
      setLoaded(true);

      return;
    }

    const promises = [apiService.getCertificateData(userIdValue), apiService.getBadgesData(userIdValue, [])];

    const results = (
      await Promise.allSettled(
        promises.map((p) =>
          p.then((result: Certification[] | Badge[] | null) => {
            return result;
          }),
        ),
      )
    ).map((result) => {
      return result.status === 'fulfilled' ? result.value : undefined;
    });
    const [certificateData, badgesData] = results;
    setBookings(bookingData);
    setCertificates(certificateData as Certification[]);
    setBadges(((badgesData as unknown as Badge[]) || []).filter((badge) => badge.badge));

    setLoading(false);
    setLoaded(true);
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    setUserId(queryParams.get('hmguid') || '');
  }, [location]);

  const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    resetState();
    const userIdText = e.currentTarget[0] as HTMLInputElement;
    await collectUserData(userIdText.value);
  };

  const openLuxReloadedPreview = async (userId: string) => {
    const token = await apiService.getTokenForLuk(userId, 15);
    console.log(token);
    console.log(config.LUX_URL);
    const url = `${config.LUX_URL}/login?authentication=${token}`;
    window.open(url, '_blank');
  };

  return (
    <>
      <Box mt={2}>
        <Typography variant="h1" component="h2" className="m-4">
          {t('pages.luPreview.title')}
        </Typography>
      </Box>

      <p>{t('pages.luPreview.content')}</p>

      <Box mt={5} mb={7}>
        <form autoComplete="off" onSubmit={submitHandler}>
          <Grid container spacing={2} justifyContent="space-between" alignItems="center">
            <Grid item xs={12} md={5}>
              <Box display="flex" alignItems="flex-end" className={classes.formContainer}>
                <TextField
                  id="standard-basic"
                  required
                  label={t('pages.luPreview.form.userId')}
                  className={classes.textField}
                  onChange={(event) => setUserId(event.target.value)}
                  value={userId}
                  fullWidth
                />
                <Button variant="contained" color="primary" type="submit" disabled={!userId}>
                  {t('pages.luPreview.form.send')}
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} md={7}>
              {loaded && bookings && (
                <Button
                  onClick={() => openLuxReloadedPreview(bookings.userId)}
                  variant="contained"
                  color="secondary"
                  endIcon={<LaunchOutlinedIcon />}
                  className={classes.btnPreview}
                >
                  {t('pages.luPreview.toPreviewLinkLabelNew')}
                </Button>
              )}
            </Grid>
          </Grid>
        </form>
      </Box>

      {loaded && (
        <p>
          <b>
            {t('pages.luPreview.missingBookingsSupportText')}{' '}
            <a href={jiraLink} target="_blank" rel="noreferrer">
              Anlegen eines JIRA-Tickets (BUM)
            </a>
          </b>
        </p>
      )}
      <>
        {!loaded && !loading && (
          <Alert severity="info">
            <p>
              Um die LU-Vorschau und das Cockpit für die Teilnahmebestätigungen vollumfänglich nutzen zu können, beachte
              bitte folgende Punkte:
            </p>
            <ul>
              <li>Du musst im ARGO-Backoffice angemeldet sein</li>
              <li>Der Teilnehmer, dessen Vorschau du prüfen möchtest, muss korrekt in ARGO angelegt sein</li>
              <li>Du benötigst die HGUID des Teilnehmers</li>
            </ul>
            <p>
              Alle Informationen rund um die LU-Vorschau findest du in der{' '}
              <a
                href="https://mywiki.grp.haufemg.com/pages/viewpage.action?pageId=151554001"
                target="_blank"
                rel="noreferrer"
              >
                WIKI-Doku
              </a>
            </p>
          </Alert>
        )}
        {userNotFound && <Alert severity="warning">Keinen User zu der HmguserID gefunden.</Alert>}
        {!userNotFound && (
          <MainTable
            loading={loading}
            loaded={loaded}
            bookings={bookings}
            badges={badges}
            certificates={certificates}
          />
        )}
      </>
    </>
  );
};

export { LUPreviewPage };
