import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { i18nDe } from './de';
import { i18nEn } from './en';

i18n.use(initReactI18next).init({
  resources: {
    de: { translation: i18nDe },
    en: { translation: i18nEn },
  },
  initImmediate: true,
  lng: 'de',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

// eslint-disable-next-line import/no-default-export
export default i18n;
