import React from 'react';
import format from 'date-fns/format';
import { parseISO } from 'date-fns';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import MinimizeIcon from '@material-ui/icons/Minimize';
import { TFunction } from 'react-i18next';
import { Column } from 'react-table';

import { ColorPalette } from '../theme/ColorPalette';
import { EventBooking } from '../typings/user-bookings';

const ColumnsDefEventBookings = (t: TFunction<'translation'>): Column<EventBooking>[] => {
  return [
    {
      Header: t('pages.luPreview.tables.eventBookings.headers.title').toString(),
      accessor: 'eventTitle',
      minWidth: 400,
      width: 500,
      maxWidth: 700,
    },
    {
      Header: t('pages.luPreview.tables.eventBookings.headers.bookingNum').toString(),
      accessor: 'bookingNumber',
      minWidth: 100,
      width: 150,
      maxWidth: 300,
    },
    {
      Header: t('pages.luPreview.tables.eventBookings.headers.vaNum').toString(),
      accessor: 'eventId',
      minWidth: 100,
      width: 150,
      maxWidth: 300,
    },
    {
      Header: t('pages.luPreview.tables.eventBookings.headers.bookingType').toString(),
      accessor: 'bookingType',
      minWidth: 100,
      width: 200,
      maxWidth: 300,
    },
    {
      Header: t('pages.luPreview.tables.eventBookings.headers.format').toString(),
      accessor: 'format',
      minWidth: 50,
      width: 100,
      maxWidth: 200,
    },
    {
      Header: t('pages.luPreview.tables.eventBookings.headers.avendooCourseId').toString(),
      accessor: 'avendooId',
      minWidth: 100,
      width: 225,
      maxWidth: 300,
    },
    {
      Header: t('pages.luPreview.tables.eventBookings.headers.beginDate').toString(),
      accessor: 'startDate',
      minWidth: 100,
      width: 150,
      maxWidth: 300,
      Cell: ({ cell: { value } }) => (value ? format(parseISO(value), t('formatDate')) : ''),
    },
    {
      Header: t('pages.luPreview.tables.eventBookings.headers.endDate').toString(),
      accessor: 'endDate',
      minWidth: 100,
      width: 150,
      maxWidth: 300,
      Cell: ({ cell: { value } }) => (value ? format(parseISO(value), t('formatDate')) : ''),
    },
    {
      Header: t('pages.luPreview.tables.eventBookings.headers.hotel').toString(),
      accessor: 'hotelName',
      minWidth: 100,
      width: 250,
      maxWidth: 350,
    },
    {
      Header: t('pages.luPreview.tables.eventBookings.headers.materialAtHome').toString(),
      accessor: 'materialAtHome',
      minWidth: 100,
      width: 150,
      maxWidth: 300,
      Cell: ({ cell: { value } }) =>
        value ? <CheckBoxIcon style={{ color: ColorPalette.colors.emerald }} /> : <MinimizeIcon color="disabled" />,
    },
  ];
};

export { ColumnsDefEventBookings };
