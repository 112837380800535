export const i18nDe = {
  pages: {
    luPreview: {
      title: 'LU-Vorschau',
      content:
        'Bitte gib die HGUID des Teilnehmers in untenstehendem Feld ein und klicke auf "Senden". Die Daten werden dir im Anschluss angezeigt.',
      missingBookingsSupportText: 'Bei fehlenden Buchungen bitte das Ticket direkt der Domäne BUM zuweisen.',
      form: {
        userId: 'HMGUSER ID',
        send: 'Senden',
      },
      tabs: {
        headers: {
          eventBookings: 'VA-Buchungen',
          lehrgangBookings: 'Lehrgang-Buchungen',
          coachingBookings: 'Coachings',
          badges: 'Badges',
          tbs: 'Digitale TBs',
        },
      },
      tables: {
        eventBookings: {
          headers: {
            title: 'Title',
            bookingNum: 'BuchNr',
            vaNum: 'VA-Nr',
            bookingType: 'BuchTyp',
            format: 'Format',
            avendooCourseId: 'Avendoo-Kurs-ID',
            beginDate: 'Beginn',
            endDate: 'Ende',
            hotel: 'Hotel',
            materialAtHome: 'Material@Home',
          },
        },
        lehrgangBookings: {
          headers: {
            title: 'Title',
            lgSapId: 'LG-Variante',
            id: 'LG-Variante_TN-Nr',
            reqNumOptModules: 'zu buchende Wahlseminare',
            mandatoryModules: 'Pflichtseminare (VA-Typ)',
            optionalModules: 'Wahlseminare (VA-Typ)',
          },
          sapVaTypId: 'SAP VA-Typ ID',
          lopId: 'LOP ID',
        },
        coachingBookings: {
          headers: {
            title: 'Title',
            coachingId: 'Coaching-ID',
            startDate: 'Gültig von',
            endDate: 'Gültig bis',
            coach: 'Coach',
            location: 'Ort',
            totalDuration: 'Kontingent',
          },
          locationType: {
            onsite: 'Präsenz',
            remote: 'Online',
            hybridOnsite: 'Hybrid/primär vor Ort',
            hybridRemote: 'Hybrid/primär Online',
            unknown: 'Ort unbekannt',
          },
        },
        badges: {
          headers: {
            title: 'Title',
            image: 'Bild',
            vaNum: 'VA-Nr',
            validFrom: 'Gültig ab',
            validUntil: 'Gültig bis',
            detailPage: 'Detailseite',
            mappingAvailable: 'Mapping verfügbar',
          },
          unlimited: 'unbegrenzt',
          yes: 'Ja',
          no: 'Nein',
        },
        tbs: {
          headers: {
            title: 'Title',
            firstName: 'Vorname',
            lastName: 'Nachname',
            issueDate: 'Ausstellungsdatum',
            location: 'Ort',
            startDate: 'Beginn',
            endDate: 'Ende',
            download: 'Download',
          },
          downloadLink: 'Downloadlink',
        },
      },
      toPreviewLinkLabel: 'Zur LU-Vorschau',
      toPreviewLinkLabelNew: 'Zur LU-Vorschau (neu)',
      emptyBadges: 'Keine Badges gefunden',
      emptyTBs: 'Keine TBs gefunden',
      emptyEventBookings: 'Keine VA-Buchungen gefunden',
      emptyLehrgangBookings: 'Keine Lehrgang-Buchungen gefunden',
      emptyCoachingBookings: 'Keine Coachings gefunden',
      emptyUser: 'User not found',
    },
  },
  formatDate: 'dd.MM.yyyy',
  formatDateWithTime: 'dd.MM.yyyy HH:mm',
};
