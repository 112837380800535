import React, { FunctionComponent } from 'react';
import { Badge } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { ColorPalette } from '../theme/ColorPalette';

type TabLabelProps = {
  label: string;
  count: number;
};

const useStyles = makeStyles((theme) => ({
  badge: {
    '& .lupreview-MuiBadge-badge': {
      position: 'relative',
      transform: 'none',
      top: '1px',
      marginLeft: theme.spacing(0.75),
      background: ColorPalette.monochrome.grey60,
      color: ColorPalette.monochrome.white,
    },
  },
}));

const TabLabel: FunctionComponent<TabLabelProps> = ({ label, count }) => {
  const classes = useStyles();

  return (
    <Badge badgeContent={count} color="primary" className={classes.badge}>
      {label}
    </Badge>
  );
};

export { TabLabel };
