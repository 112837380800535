import { Bookings } from '../typings/user-bookings';
import { Certification } from '../typings/certificate';
import { Badge } from '../typings/badges';
import { mergedConfig } from '../config';

type CertificateUrlResponse = {
  url: string;
};

export const getApiService = (getJWT: () => Promise<string | undefined | null>) => {
  const apiRequest = async (
    path: string,
    method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
    body?: BodyInit,
    params?: Record<string, string>,
  ) => {
    const jwt = await getJWT();
    if (!jwt) {
      throw new Error('Could not get JWT');
    }

    const result = await fetch(`${mergedConfig.API_URL}/${path}?${new URLSearchParams(params)}`, {
      method,
      body,
      headers: {
        'Content-Type': 'application/json',
        authorization: jwt ?? '',
      },
    });

    if (result.status >= 400) {
      const data = await result.json();
      throw new Error(data.message);
    }

    return result.text();
  };

  const getCertificateUrl = async (certificateId: string): Promise<CertificateUrlResponse | null> => {
    const result = await apiRequest(`certificate-url/${certificateId}`, 'GET');
    return JSON.parse(result);
  };

  const syncBookingData = async (hmguserid: string): Promise<void> => {
    const result = await apiRequest(`sync-booking-data/${hmguserid}`, 'GET');
    return JSON.parse(result);
  };

  const getBookingData = async (hmguserid: string): Promise<Bookings> => {
    const result = await apiRequest(`users/${hmguserid}/bookings`, 'GET');
    return JSON.parse(result);
  };

  const getCertificateData = async (hmguserid: string, eventId?: string): Promise<Certification[]> => {
    const path = !eventId ? `users/${hmguserid}/certificates` : `users/${hmguserid}/certificates?eventId=${eventId}`;
    const result = await apiRequest(path, 'GET');
    return JSON.parse(result);
  };

  const getBadgesData = async (hmguserid: string, courseIds: string[]): Promise<Badge[]> => {
    const result = await apiRequest(`users/${hmguserid}/badges`, 'GET', undefined, {
      courseIds: JSON.stringify(courseIds),
    });
    return JSON.parse(result);
  };

  const getTokenForLuk = async (hmguserid: string, expireMinutes: number): Promise<string> => {
    const result = await apiRequest(`luk/get-token?hmgUserId=${hmguserid}&expireMinutes=${expireMinutes}`, 'GET');
    return result;
  };

  return {
    syncBookingData,
    getBookingData,
    getCertificateData,
    getBadgesData,
    getCertificateUrl,
    getTokenForLuk,
  };
};
