import React from 'react';
import format from 'date-fns/format';
import { parseISO } from 'date-fns';
import Link from '@material-ui/core/Link';
import { TFunction } from 'react-i18next';
import { Column } from 'react-table';

import { Certification } from '../typings/certificate';

const ColumnsDefTBs = (
  t: TFunction<'translation'>,
  onClickCertificateLink: (certificateId: string) => void,
): Column<Certification>[] => {
  return [
    {
      Header: t('pages.luPreview.tables.tbs.headers.title').toString(),
      accessor: 'eventTitle',
      minWidth: 200,
      width: 340,
      maxWidth: 500,
    },
    {
      Header: t('pages.luPreview.tables.tbs.headers.firstName').toString(),
      accessor: 'firstName',
      minWidth: 100,
      width: 200,
      maxWidth: 300,
    },
    {
      Header: t('pages.luPreview.tables.tbs.headers.lastName').toString(),
      accessor: 'lastName',
      minWidth: 100,
      width: 200,
      maxWidth: 300,
    },
    {
      Header: t('pages.luPreview.tables.tbs.headers.issueDate').toString(),
      accessor: 'issueDate',
      minWidth: 100,
      width: 200,
      maxWidth: 300,
      Cell: ({ cell: { value } }) => (value ? format(parseISO(value), t('formatDate')) : ''),
    },
    {
      Header: t('pages.luPreview.tables.tbs.headers.location').toString(),
      accessor: 'location',
      minWidth: 100,
      width: 200,
      maxWidth: 300,
    },
    {
      Header: t('pages.luPreview.tables.tbs.headers.startDate').toString(),
      accessor: 'startDate',
      minWidth: 100,
      width: 200,
      maxWidth: 300,
      Cell: ({ cell: { value } }) => (value ? format(parseISO(value), t('formatDate')) : ''),
    },
    {
      Header: t('pages.luPreview.tables.tbs.headers.endDate').toString(),
      accessor: 'endDate',
      minWidth: 100,
      width: 200,
      maxWidth: 300,
      Cell: ({ cell: { value } }) => (value ? format(parseISO(value), t('formatDate')) : ''),
    },
    {
      Header: t('pages.luPreview.tables.tbs.headers.download').toString(),
      accessor: 'id',
      minWidth: 100,
      width: 200,
      maxWidth: 300,
      Cell: ({ cell: { value } }) => (
        <Link component="button" variant="body2" color="primary" onClick={() => onClickCertificateLink(value)}>
          {t('pages.luPreview.tables.tbs.downloadLink')}
        </Link>
      ),
    },
  ];
};

export { ColumnsDefTBs };
