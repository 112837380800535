import React from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { TFunction } from 'react-i18next';
import { Column } from 'react-table';

import { LehrgangBooking } from '../typings/user-bookings';

type LehrgangModule = {
  productId: string;
  lopProductId: string;
};

const moduleCell = (t: TFunction<'translation'>, modules: LehrgangModule[]) =>
  modules ? (
    <TableContainer style={{ padding: 0 }}>
      <Table aria-label="simple table" style={{ paddingTop: 0 }}>
        <TableHead>
          <TableRow>
            <TableCell style={{ paddingTop: 0, paddingLeft: 0 }}>
              {t('pages.luPreview.tables.lehrgangBookings.sapVaTypId')}
            </TableCell>
            <TableCell style={{ paddingTop: 0, paddingRight: 0 }}>
              {t('pages.luPreview.tables.lehrgangBookings.lopId')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {modules.map((row) => (
            <TableRow key={row.productId}>
              <TableCell style={{ paddingLeft: 0 }}>{row.productId}</TableCell>
              <TableCell style={{ paddingRight: 0 }}>{row.lopProductId}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  ) : null;

const ColumnsDefLehrgangBookings = (t: TFunction<'translation'>): Column<LehrgangBooking>[] => {
  return [
    {
      Header: t('pages.luPreview.tables.lehrgangBookings.headers.title').toString(),
      accessor: 'lehrgangTitle',
      minWidth: 30,
      width: 300,
      maxWidth: 400,
    },
    {
      Header: t('pages.luPreview.tables.lehrgangBookings.headers.lgSapId').toString(),
      accessor: 'lehrgangId',
      minWidth: 100,
      width: 200,
      maxWidth: 300,
    },
    {
      Header: t('pages.luPreview.tables.lehrgangBookings.headers.reqNumOptModules').toString(),
      accessor: 'numberOfRequiredOptionalModules',
      minWidth: 100,
      width: 150,
      maxWidth: 300,
    },
    {
      Header: t('pages.luPreview.tables.lehrgangBookings.headers.mandatoryModules').toString(),
      accessor: 'mandatoryModules',
      minWidth: 280,
      width: 280,
      maxWidth: 400,
      Cell: ({ cell: { value } }) => moduleCell(t, value),
    },
    {
      Header: t('pages.luPreview.tables.lehrgangBookings.headers.optionalModules').toString(),
      accessor: 'optionalModules',
      minWidth: 280,
      width: 280,
      maxWidth: 400,
      Cell: ({ cell: { value } }) => moduleCell(t, value),
    },
  ];
};

export { ColumnsDefLehrgangBookings };
