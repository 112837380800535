import { createContext } from 'react';

export type JWTContextProps = {
  getJWT: () => Promise<string | undefined | null>;
};

const JWTContext = createContext<JWTContextProps>({
  getJWT: async () => {
    // eslint-disable-next-line no-console
    console.log('not set');
    return null;
  },
});

export { JWTContext };
