/* eslint-disable react/jsx-key */
import React, { PropsWithChildren, ReactElement } from 'react';
import MaUTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useTable, useResizeColumns, useBlockLayout, usePagination, Column } from 'react-table';
import { Box, makeStyles } from '@material-ui/core';

import { ColorPalette } from '../../theme/ColorPalette';

type SmartTableProps<T extends object> = {
  columns: Column<T>[];
  data: T[];
};

const useStyles = makeStyles((theme) => ({
  resizer: {
    display: 'inline-block',
    borderLeft: `1px dashed ${ColorPalette.monochrome.grey40}`,
    width: 10,
    position: 'absolute',
    right: 0,
    top: 10,
    bottom: 10,
    transform: 'translateX(50%)',
    zIndex: 1,
  },
  tableBody: {
    overflowX: 'auto',
  },
  pagination: {
    textAlign: 'right',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(2),
  },
}));

const SmartTable = <T extends object>({
  columns,
  data,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: PropsWithChildren<SmartTableProps<T>>): ReactElement<any, any> | null => {
  const classes = useStyles();
  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 30,
    }),
    [],
  );

  const {
    getTableProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: { pageIndex: 0 },
    },
    useBlockLayout,
    useResizeColumns,
    usePagination,
  );

  // Render the UI for your table
  return (
    <>
      <Box className={`${classes.tableBody} tableBoxAnchor`}>
        <MaUTable {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableCell {...column.getHeaderProps()}>
                    {column.render('Header')}
                    <Box {...column.getResizerProps()} className={classes.resizer} />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {page.map((row) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return <TableCell {...cell.getCellProps()}>{cell.render('Cell')}</TableCell>;
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </MaUTable>
      </Box>

      <Box className={classes.pagination}>
        <IconButton onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          <FirstPageIcon />
        </IconButton>{' '}
        <IconButton onClick={() => previousPage()} disabled={!canPreviousPage}>
          <NavigateBeforeIcon />
        </IconButton>{' '}
        <IconButton onClick={() => nextPage()} disabled={!canNextPage}>
          <NavigateNextIcon />
        </IconButton>{' '}
        <IconButton onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          <LastPageIcon />
        </IconButton>{' '}
        <Box ml={1} display="inline-block">
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </Box>
      </Box>
    </>
  );
};

export { SmartTable };
