import ReactDOM from 'react-dom';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { IMicroFrontendInstance } from '@hmg-aka-lu/cockpit-frontend/dist-types/exports';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { JWTContext } from './components/JWTContext';
import { LightHouseThemeProvider } from './theme/LightHouseThemeProvider';
import { LUPreviewPage } from './pages/LUPreviewPage';
import { mergedConfig, MICROFRONTEND_ID } from './config';
import './theme/FontFace.css';
import './i18n';

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    [MICROFRONTEND_ID]: IMicroFrontendInstance;
  }
}

// eslint-disable-next-line default-param-last, @typescript-eslint/no-explicit-any
const microFrontendInstance: IMicroFrontendInstance = (containerId = 'root', history: any, microfrontendMeta) => {
  const queryClient = new QueryClient();

  return {
    render: () => {
      ReactDOM.render(
        <LightHouseThemeProvider>
          <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            <JWTContext.Provider value={{ getJWT: microfrontendMeta.getJWT }}>
              <BrowserRouter>
                <Routes>
                  <Route path={`/${MICROFRONTEND_ID}`} element={<LUPreviewPage />} />
                  <Route path="*" element={<Navigate replace to={`/${MICROFRONTEND_ID}/`} />} />
                </Routes>
              </BrowserRouter>
            </JWTContext.Provider>
          </QueryClientProvider>
        </LightHouseThemeProvider>,
        document.getElementById(containerId),
      );
    },
  };
};

window.lupreview = microFrontendInstance;

if (mergedConfig.STANDALONE) {
  const microFrontend = microFrontendInstance(
    'root',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    undefined,
    {
      getJWT: async () => {
        return '';
      },
      hasCockpitAccess: () => true,
      hasRequiredGroup: () => true,
      authenticatedContext: {},
      relativeUrl: '12312',
    },
  );
  microFrontend.render();
}
