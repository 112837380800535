import { ReactNode } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { StylesProvider, createGenerateClassName, ThemeProvider } from '@material-ui/core';

import { LightHouseTheme } from './LightHouseTheme';

type ThemeWrapperProps = {
  children: ReactNode;
};

const generateClassName = createGenerateClassName({
  productionPrefix: 'lupreview',
  seed: 'lupreview',
});

const LightHouseThemeProvider = ({ children }: ThemeWrapperProps) => {
  return (
    <StylesProvider generateClassName={generateClassName} injectFirst>
      <ThemeProvider key="lupreview-theme" theme={LightHouseTheme}>
        <CssBaseline>{children}</CssBaseline>
      </ThemeProvider>
    </StylesProvider>
  );
};

export { LightHouseThemeProvider };
