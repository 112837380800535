type EnvironmentSpecificConfig = {
  API_URL: string;
  LUX_URL: string;
  BADGE_ASSERTION_URL: string;
};

const dev: EnvironmentSpecificConfig = {
  API_URL: 'http://localhost:8088/dev',
  LUX_URL: 'http://localhost:3000',
  BADGE_ASSERTION_URL: 'https://frontend.haufe-badges-test.com/assertions/',
};

const test: EnvironmentSpecificConfig = {
  API_URL: 'https://98nwdcp9r9-vpce-01d68c968497e31fc.execute-api.eu-central-1.amazonaws.com/test',
  LUX_URL: 'https://lernumgebung-test.lu.akadev.de',
  BADGE_ASSERTION_URL: 'https://frontend.haufe-badges-test.com/assertions/',
};

const prod: EnvironmentSpecificConfig = {
  API_URL: 'https://5sx66ajgm6-vpce-00ce5466256a389ef.execute-api.eu-central-1.amazonaws.com/prod',
  LUX_URL: 'https://lernumgebung.haufe-akademie.de',
  BADGE_ASSERTION_URL: 'https://frontend.haufe-badges.com/assertions/',
};

const env = process.env.REACT_APP_STAGE || 'dev';

// eslint-disable-next-line no-nested-ternary
const config = env === 'test' ? test : env === 'prod' ? prod : dev;

const mergedConfig = {
  ...config,
  STANDALONE: env === 'dev',
};

const MICROFRONTEND_ID = 'lupreview';

export { mergedConfig, MICROFRONTEND_ID };
