import format from 'date-fns/format';
import { parseISO } from 'date-fns';
import { TFunction } from 'react-i18next';
import { Column } from 'react-table';

import { CoachingBooking } from '../typings/user-bookings';

const ColumnsDefCoachingBookings = (t: TFunction<'translation'>): Column<CoachingBooking>[] => {
  return [
    {
      Header: t('pages.luPreview.tables.coachingBookings.headers.title').toString(),
      accessor: 'title',
      minWidth: 200,
      width: 400,
      maxWidth: 600,
    },
    {
      Header: t('pages.luPreview.tables.coachingBookings.headers.coachingId').toString(),
      accessor: 'coachingId',
      minWidth: 150,
      width: 150,
      maxWidth: 400,
    },
    {
      Header: t('pages.luPreview.tables.coachingBookings.headers.startDate').toString(),
      accessor: 'startDate',
      minWidth: 125,
      width: 125,
      maxWidth: 125,
      Cell: ({ cell: { value: startDate } }) => format(parseISO(startDate), t('formatDate')),
    },
    {
      Header: t('pages.luPreview.tables.coachingBookings.headers.endDate').toString(),
      accessor: 'endDate',
      minWidth: 125,
      width: 125,
      maxWidth: 125,
      Cell: ({ cell: { value: endDate } }) => format(parseISO(endDate), t('formatDate')),
    },
    {
      Header: t('pages.luPreview.tables.coachingBookings.headers.coach').toString(),
      accessor: 'coach',
      minWidth: 100,
      width: 150,
      maxWidth: 300,
      Cell: ({ cell: { value: coach } }) => {
        const title = coach.title ? `${coach.title},` : '';
        return `${title} ${coach.firstName} ${coach.lastName}`;
      },
    },
    {
      Header: t('pages.luPreview.tables.coachingBookings.headers.location').toString(),
      accessor: 'location',
      minWidth: 100,
      width: 150,
      maxWidth: 400,
      Cell: ({ cell: { value: location } }) => {
        const locationText = location.text?.length ? ` | ${location.text}` : '';
        switch (location.type) {
          case 'remote':
            return t('pages.luPreview.tables.coachingBookings.locationType.remote');
          case 'onsite':
            return `${t('pages.luPreview.tables.coachingBookings.locationType.onsite')}${locationText}`;
          case 'hybridOnsite':
            return `${t('pages.luPreview.tables.coachingBookings.locationType.hybridOnsite')}${locationText}`;
          case 'hybridRemote':
            return `${t('pages.luPreview.tables.coachingBookings.locationType.hybridRemote')}${locationText}`;
          default:
            return t('pages.luPreview.tables.coachingBookings.locationType.unknown');
        }
      },
    },
    {
      Header: t('pages.luPreview.tables.coachingBookings.headers.totalDuration').toString(),
      accessor: 'totalDuration',
      minWidth: 125,
      width: 125,
      maxWidth: 125,
      Cell: ({ cell: { value } }) => `${value} min`,
    },
  ];
};

export { ColumnsDefCoachingBookings };
