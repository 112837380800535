import React from 'react';
import format from 'date-fns/format';
import { parseISO } from 'date-fns';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import { TFunction } from 'react-i18next';
import { Column } from 'react-table';

import { Badge } from '../typings/badges';
import { mergedConfig as config } from '../config';

const ColumnsDefBadges = (t: TFunction<'translation'>): Column<Badge>[] => {
  return [
    {
      Header: t('pages.luPreview.tables.badges.headers.title').toString(),
      id: 'badge-title',
      accessor: 'badge',
      minWidth: 200,
      width: 500,
      maxWidth: 500,
      Cell: ({
        cell: {
          value: { title },
        },
      }) => title,
    },
    {
      Header: t('pages.luPreview.tables.badges.headers.image').toString(),
      id: 'badge-image',
      accessor: 'badge',
      Cell: ({ cell: { value } }) => (
        <Box display="flex">
          <a
            href={value.badgeImageUrl}
            style={{ height: 50 }}
            target="_blank"
            rel="noreferrer"
            aria-label="badge image"
          >
            <img src={value.badgeImageUrl} alt="" width={50} />
          </a>
        </Box>
      ),
    },
    {
      Header: t('pages.luPreview.tables.badges.headers.vaNum').toString(),
      id: 'badge-event-id',
      accessor: 'badge',
      Cell: ({
        cell: {
          value: { parentId },
        },
      }) => parentId,
    },
    {
      Header: t('pages.luPreview.tables.badges.headers.validFrom').toString(),
      id: 'badge-valid-from',
      accessor: 'badge',
      Cell: ({
        cell: {
          value: { validFrom },
        },
      }) => (validFrom ? format(parseISO(validFrom), t('formatDate')) : ''),
    },
    {
      Header: t('pages.luPreview.tables.badges.headers.title').toString(),
      id: 'badge-link',
      accessor: 'badge',
      Cell: ({
        cell: {
          value: { badgeId },
        },
      }) => (
        <Link color="primary" href={`${config.BADGE_ASSERTION_URL}${badgeId}`} target="_blank" rel="noreferrer">
          Link
        </Link>
      ),
    },
  ];
};

export { ColumnsDefBadges };
