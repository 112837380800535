import React, { FunctionComponent, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AppBar, Tab, Tabs } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

import { Badge } from '../typings/badges';
import { Bookings } from '../typings/user-bookings';
import { Certification } from '../typings/certificate';
import { ColorPalette } from '../theme/ColorPalette';
import { getApiService } from '../api/api-request';
import { JWTContext } from '../components/JWTContext';
import { SmartTable } from '../components/SmartTable/SmartTable';

import { AlertObjectNotFound } from './AlertObjectNotFound';
import { ColumnsDefBadges } from './ColumnsDefBadges';
import { ColumnsDefEventBookings } from './ColumnsDefEventBookings';
import { ColumnsDefCoachingBookings } from './ColumnsDefCoachingBookings';
import { ColumnsDefLehrgangBookings } from './ColumnsDefLehrgangBookings';
import { ColumnsDefTBs } from './ColumnsDefTBs';
import { TabLabel } from './TabLabel';
import { TabPanel } from './TabPanel';

const useStyles = makeStyles((theme) => ({
  tabs: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: ColorPalette.monochrome.grey10,
    '& .Mui-selected .lupreview-MuiBadge-badge': {
      backgroundColor: ColorPalette.primary.main,
    },
    '& .lupreview-MuiTab-root': {
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
    },
  },
}));

type MainTableProps = {
  loading: boolean;
  loaded: boolean;
  bookings: Bookings | null;
  badges: Badge[];
  certificates: Certification[];
};

const MainTable: FunctionComponent<MainTableProps> = ({
  loading,
  loaded,
  bookings,
  badges,
  certificates,
}: MainTableProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { getJWT } = useContext(JWTContext);
  const apiService = getApiService(getJWT);

  const [value, setValue] = React.useState(0);

  const handleChange = (_event: React.ChangeEvent<Record<string, unknown>>, newValue: number) => {
    setValue(newValue);
  };

  const downloadCertificate = async (certifcateId: string) => {
    const certificateUrl = await apiService.getCertificateUrl(certifcateId);
    if (certificateUrl) {
      window.open(certificateUrl.url, '_blank');
    }
  };

  return (
    <>
      {loading && <LinearProgress />}
      {!loading && loaded && (
        <Box className={classes.tabs}>
          <AppBar position="static" color="default" elevation={1}>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
              className={classes.tabs}
            >
              <Tab
                label={
                  <TabLabel
                    label={t('pages.luPreview.tabs.headers.eventBookings')}
                    count={bookings?.eventBookings?.length || 0}
                  />
                }
                id="scrollable-auto-tab-0"
                aria-controls="scrollable-auto-tabpanel-0"
              />
              <Tab
                label={
                  <TabLabel
                    label={t('pages.luPreview.tabs.headers.lehrgangBookings')}
                    count={bookings?.lehrgangBookings?.length || 0}
                  />
                }
                id="scrollable-auto-tab-1"
                aria-controls="scrollable-auto-tabpanel-1"
              />
              <Tab
                label={
                  <TabLabel
                    label={t('pages.luPreview.tabs.headers.coachingBookings')}
                    count={bookings?.coachingBookings?.length || 0}
                  />
                }
                id="scrollable-auto-tab-2"
                aria-controls="scrollable-auto-tabpanel-2"
              />
              <Tab
                label={<TabLabel label={t('pages.luPreview.tabs.headers.badges')} count={badges?.length || 0} />}
                id="scrollable-auto-tab-3"
                aria-controls="scrollable-auto-tabpanel-3"
              />
              <Tab
                label={<TabLabel label={t('pages.luPreview.tabs.headers.tbs')} count={certificates?.length || 0} />}
                id="scrollable-auto-tab-4"
                aria-controls="scrollable-auto-tabpanel-4"
              />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            {bookings?.eventBookings?.[0] ? (
              <Paper>
                <SmartTable columns={ColumnsDefEventBookings(t)} data={bookings.eventBookings} />
              </Paper>
            ) : (
              <AlertObjectNotFound text={t('pages.luPreview.emptyEventBookings')} />
            )}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {bookings?.lehrgangBookings?.[0] ? (
              <Paper>
                <SmartTable columns={ColumnsDefLehrgangBookings(t)} data={bookings.lehrgangBookings} />
              </Paper>
            ) : (
              <AlertObjectNotFound text={t('pages.luPreview.emptyLehrgangBookings')} />
            )}
          </TabPanel>
          <TabPanel value={value} index={2}>
            {bookings?.coachingBookings?.[0] ? (
              <Paper>
                <SmartTable columns={ColumnsDefCoachingBookings(t)} data={bookings.coachingBookings} />
              </Paper>
            ) : (
              <AlertObjectNotFound text={t('pages.luPreview.emptyCoachingBookings')} />
            )}
          </TabPanel>
          <TabPanel value={value} index={3}>
            {badges?.length > 0 ? (
              <Paper>
                <SmartTable columns={ColumnsDefBadges(t)} data={badges} />
              </Paper>
            ) : (
              <AlertObjectNotFound text={t('pages.luPreview.emptyBadges')} />
            )}
          </TabPanel>
          <TabPanel value={value} index={4}>
            {certificates.length > 0 ? (
              <Paper>
                <SmartTable columns={ColumnsDefTBs(t, downloadCertificate)} data={certificates} />
              </Paper>
            ) : (
              <AlertObjectNotFound text={t('pages.luPreview.emptyTBs')} />
            )}
          </TabPanel>
        </Box>
      )}
    </>
  );
};

export { MainTable };
