import React, { FunctionComponent } from 'react';
import Box from '@material-ui/core/Box';

type TabPanelProps = {
  children?: React.ReactNode;
  index: number;
  value: number;
};

const TabPanel: FunctionComponent<TabPanelProps> = ({ children, index, value, ...other }) => {
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={0}>{children}</Box>}
    </Box>
  );
};

export { TabPanel };
