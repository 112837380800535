import React, { FunctionComponent } from 'react';
import { Box, Paper } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { makeStyles } from '@material-ui/core/styles';

type AlertObjectNotFoundProps = {
  text: string;
};

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(0.5),
  },
}));

const AlertObjectNotFound: FunctionComponent<AlertObjectNotFoundProps> = ({ text }) => {
  const classes = useStyles();

  return (
    <Paper elevation={1}>
      <Box px={3} py={4} display="flex" alignItems="center">
        <InfoOutlinedIcon color="secondary" className={classes.icon} /> {text}
      </Box>
    </Paper>
  );
};

export { AlertObjectNotFound };
